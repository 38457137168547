import React, { useEffect } from 'react';

const SubmitContentPage = () => {
  useEffect(() => {
    window.location.replace(
      'https://docs.google.com/forms/d/e/1FAIpQLSc-jrtHXdWcsERBneMH8dOckW9dXFxa9PQSvdUTkKNBRCIGJA/viewform'
    );
  }, []);

  return <div></div>;
};

export default SubmitContentPage;
